import React from "react"
import { Helmet } from "react-helmet"
import "react-responsive-modal/styles.css"
import Slider from "react-slick"
import Layout from "../components/layout"
import ArenaTeam from "../images/arena-team.png"
import CreativeTeam from "../images/creative-team.jpg"
import MATeam from "../images/MATeam.jpg"
import TeamLunch from "../images/TeamLunch.jpg"
import Shapefour from "../images/bannerElement/white-design-element-career.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import HotelResortLeft from "../images/hotelsandresortsleft.jpg"
import HotelResortRight from "../images/hotelsandresortsright.jpg"
import HotelResortRight2 from "../images/hotelsandresortsright2.jpg"
import PPCTeam from "../images/PPCTeam.jpg"
import P2ClientMeet from "../images/p2-clientmeet.jpg"
import PushpendraKumar from "../images/PushpendraKumar.jpg"
import NidhiSarawgi from "../images/NidhiSarawgi.jpg"
import ArunaKeshavaiah from "../images/ArunaKeshavaiah.jpg"
import NishanthBelliappa from "../images/NishanthBelliappa.jpg"
import SudiptaChakraborty from "../images/SudiptaChakraborty.jpg"
import Falcons02 from "../images/falcons02.png"
import Falcons3 from "../images/falcons3.png"
import Falcons4 from "../images/falcons4.png"
import Falcons from "../images/falcons.jpg"
import AboutUSRoundTwo from "../images/AboutUSRightRoundTwo.png"
import TeamHuddle from "../images/team-huddle.jpg"
import RajivParikh from "../images/rajiv-parikh.svg"
import ShayPhilips from "../images/shay-philips.svg"
import MitsyLopezBaranello from "../images/mitsy-lopez-baranello.svg"
import EmployeerRecognition from "../images/icons/Careers/employeer-recognition.svg"
import HealthInsurance from "../images/icons/Careers/HealthInsurance.svg"
import TeamBuildingBudget from "../images/icons/Careers/Team-building-budget.svg"
import UnlimitedLearningAccess from "../images/icons/Careers/Unlimited-learning-access.svg"
import WellnessPrograms from "../images/icons/Careers/WellnessPrograms.svg"
import "../styles/careers.css"

export default function LeadershipTeam() {
  const members = [
    {
      id: 1,
      name: "Rajiv Parikh",
      designation: "Co-Founder & MD",
      image: RajivParikh,
      description:
        "Our visionary CEO brings over 20 years of management experience in marketing, technology, and services, with a proven track record of driving innovation and growth. With previous management roles at industry giants like AltaVista, Sun Microsystems, NCR, and AT&T, and a strong educational background featuring an MBA from Harvard Business School and a BS in Electrical Engineering from the University of New Hampshire, our CEO is a sought-after speaker at industry forums, sharing his expertise on social media and search marketing trends to position our company for continued success in the digital landscape.",
    },
    {
      id: 2,
      name: "Shay Philips",
      designation: "Board Member",
      image: ShayPhilips,
      description:
        'Shay, a visionary executive with over 20 years of experience in the telecommunications industry, is renowned for launching innovative products that leverage cutting-edge technologies like 5G. Her expertise in marketing, operations, and strategy, combined with her ability to create structures that promote scale and growth in start-up environments, has led to her national recognition by the Career Communications Group with the "Black Engineer of the Year Award for Outstanding Technical Contribution – Industry." Holding a BA from DePaul University in Business Management and Marketing, Shay brings a well-rounded perspective to her roles, driving results through the seamless integration of technical innovations and sound business strategies.',
    },
    {
      id: 3,
      name: "Mitsy Lopez Baranello",
      designation: "Board Member",
      image: MitsyLopezBaranello,
      description:
        "Mitsy Lopez, Group Vice President of Strategy at Huge, IPG's end-to-end experience agency, leads the Strategy group in the Brooklyn office. With her expertise in digital marketing and e-commerce, she spearheads large and complex e-commerce engagements, driving results for clients in the rapidly evolving digital landscape. Previously, Mitsy served as the North American digital and CRM strategy lead for Citibank at Publicis and the Corporate Strategy lead strategist for Lexus' hybrid and performance vehicles at Toyota Motor Sales, USA. Her impressive background, including a B.S. from Cornell University and an MBA from the UCLA Anderson School of Management, provides a solid foundation for her innovative solutions that drive growth and success for her clients.",
    },
  ]

  const settings = {
    dots: true,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 0,
    pauseOnHover: false,
    loop: false,
    speed: 300,
    slidesToShow: 1,
    appendDots: dots => (
      <ul style={{ display: "flex", gap: "20px" }}> {dots} </ul>
    ),

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const falcons_settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Careers | Growth Marketing Agency | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="careerstyle">
          <section id="Banner">
            <div className="container">
              <div className="banner-holder">
                <div className="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div className="title">
                  <div className="banner-title">
                    <h1>Join our team</h1>
                    <p>
                      Unlock your potential, shape the future of growth
                      marketing
                    </p>
                    <a
                      className="button"
                      id="3dsolutionvideo"
                      href="#openingsection"
                    >
                      View job openings
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div className="container">
              <h2>We're looking for you, growth agent!</h2>
              <p>
                Join a team where your diverse ideas are not only welcomed but
                celebrated! At Position<sup>2</sup>, you'll have the opportunity
                to grow as a digital marketer while making a tangible impact on
                our clients' business growth. Collaborate with a talented and
                passionate team, work on AI-driven marketing solutions, and
                unlock your full potential in a dynamic, fast-paced environment.
                As part of our team, you'll have the opportunity to work with US
                clients, gaining invaluable experience and exposure to the
                global market.
              </p>
            </div>
          </section>
          <section id="ImgContentSlider">
            <div class="container">
              <div className="slider-wrapper">
                <Slider {...settings} className="inner-slider">
                  <div className="each-slider">
                    <div className="text-image-holder">
                      <div className="img-wrap">
                        <img
                          width={280}
                          height={320}
                          src={SudiptaChakraborty}
                          alt="Sudipta Chakraborty"
                        />
                      </div>
                      <div class="text-wrap">
                        <div class="animation-text">
                          <span class="quote"></span>
                          <div>
                            <p>
                              "Working at Position² has been an incredibly
                              fulfilling journey for me. It's more than just a
                              workplace; it's a thriving community where
                              culture, camaraderie, collaboration, and
                              innovation intersect seamlessly. Position²'s
                              commitment to team member development through
                              training and mentorship has enabled me to expand
                              my skills and progress in my career. I'm grateful
                              to be part of this dynamic and supportive team."
                            </p>
                          </div>
                          <div class="testi-designation">
                            <p class="designation">
                              <strong>
                                Sudipta Chakraborty
                                <br />
                              </strong>
                              Customer Success Manager(OX)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="each-slider">
                    <div className="text-image-holder">
                      <div className="img-wrap">
                        <img
                          width={280}
                          height={320}
                          src={NishanthBelliappa}
                          alt="Nishanth Belliappa"
                        />
                      </div>
                      <div class="text-wrap">
                        <div class="animation-text">
                          <span class="quote"></span>
                          <div>
                            <p>
                              "I've thrived, soaking up mentorship,
                              collaborating with a dream team, and enjoying a
                              refreshing work-life blend at Position<sup>2</sup>
                              . My colleagues have been more than
                              mentors—they've been cheerleaders, champions, and
                              partners in crime. Their support and the company's
                              commitment to growth have made this journey both
                              fulfilling and fabulous."
                            </p>
                          </div>
                          <div class="testi-designation">
                            <p class="designation">
                              <strong>
                                Nishanth Belliappa
                                <br />
                              </strong>
                              Team Lead - Copywriting
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="each-slider">
                    <div className="text-image-holder">
                      <div className="img-wrap">
                        <img
                          width={280}
                          height={320}
                          src={PushpendraKumar}
                          alt="PushpendraKumar"
                        />
                      </div>
                      <div class="text-wrap">
                        <div class="animation-text">
                          <span class="quote"></span>
                          <div>
                            <p>
                              "It has been an incredible journey of 3 years with
                              lots of learnings and a lot more appreciation.
                              Continuous mentorship and trust to handle newer
                              projects have been a big motivator. Working at
                              Position², I could feel the growth in my
                              professional and personal life. The knowledge
                              transfer sessions feed the upskilling hunger while
                              the team outings add to the fun part!"
                            </p>
                          </div>
                          <div class="testi-designation">
                            <p class="designation">
                              <strong>
                                Pushpendra Kumar
                                <br />
                              </strong>
                              Senior Account Manager- Performance Marketing
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="each-slider">
                    <div className="text-image-holder">
                      <div className="img-wrap">
                        <img
                          width={280}
                          height={320}
                          src={ArunaKeshavaiah}
                          alt="Aruna Keshavaiah"
                        />
                      </div>
                      <div class="text-wrap">
                        <div class="animation-text">
                          <span class="quote"></span>
                          <div>
                            <p>"Starting my career as a fresh graduate at Position<sup>2</sup>, evolving from a front-end developer to a team lead. The company has fostered my professional growth and provided a nurturing environment that encourages innovation and continuous learning. The supportive team and dynamic projects have been instrumental in shaping my skill set and leadership abilities. Position<sup>2</sup> stands out for its commitment to excellence and a culture that values each individual's contribution. I am proud to be part of this remarkable team and look forward to contributing to our shared success in the future."</p>
                          </div>
                          <div class="testi-designation">
                            <p class="designation">
                              <strong>
                                Aruna Keshavaiah
                                <br />
                              </strong>
                              Team Lead - UI/Web
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="each-slider">
                    <div className="text-image-holder">
                      <div className="img-wrap">
                        <img
                          width={280}
                          height={320}
                          src={NidhiSarawgi}
                          alt="Nidhi Sarawgi"
                        />
                      </div>
                      <div class="text-wrap">
                        <div class="animation-text">
                          <span class="quote"></span>
                          <div>
                            <p>
                              "Position<sup>2</sup> has been an incredible place
                              to grow my career in UX. The collaborative
                              environment, supportive leadership, and exposure
                              to diverse projects have allowed me to learn and
                              expand my skill set continuously. Being part of a
                              team that delivers innovative AI-driven solutions
                              to clients is truly exciting, and I'm proud to
                              contribute to the company's success."
                            </p>
                          </div>
                          <div class="testi-designation">
                            <p class="designation">
                              <strong>
                                Nidhi Sarawgi
                                <br />
                              </strong>
                              UX Designer
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section>
          <section id="ImageDescColSection">
            <div class="container">
              <h2>Perks & benefits</h2>
              <div class="imgdesc-cardwrapper">
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Health Insurance"
                      width="100"
                      height="100"
                      src={HealthInsurance}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Health Insurance</p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Unlimited Learning Access"
                      width="100"
                      height="100"
                      src={UnlimitedLearningAccess}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Unlimited Learning Access</p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Team building budget"
                      width="100"
                      height="100"
                      src={TeamBuildingBudget}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Team building budget</p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Wellness Programs"
                      width="100"
                      height="100"
                      src={WellnessPrograms}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Wellness Programs</p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Employeer Recognition"
                      width="100"
                      height="100"
                      src={EmployeerRecognition}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Employee Recognition</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="team-gallery">
            <div class="container">
              <h2>
                Life at Position<sup>2</sup>
              </h2>
              <div class="gallery-wrapper">
                <div class="gallery-left">
                  <div class="primary-img">
                    <img src={HotelResortLeft} alt="Image" />
                  </div>
                  <div class="twogalleryimg">
                    <div class="secondaryimgone">
                      <img src={ArenaTeam} alt="Image" />
                    </div>
                    <div class="secondaryimgtwo">
                      <img src={PPCTeam} alt="Image" />
                    </div>
                  </div>
                </div>
                <div class="gallery-right">
                  <div class="twogalleryimg">
                    <div class="secondaryimgone">
                      <img src={CreativeTeam} alt="Image" />
                    </div>
                    <div class="secondaryimgtwo">
                      <img src={TeamLunch} alt="Image" />
                    </div>
                  </div>
                  <div class="primary-img">
                    <img src={HotelResortRight} alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="openingsection">
            <div class="container">
              <h2>Current job openings</h2>
              <div class="openingswrap">
                <a class="eachopening" href="/careers/paid-search-manager/">
                  <p class="city">Bangalore</p>
                  <h4>Paid Search Manager</h4>
                </a>

                <a class="eachopening" href="/careers/senior-graphic-designer/">
                  <p class="city">Bangalore</p>
                  <h4>Senior Graphic Designer</h4>
                </a>
              </div>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Falcon Campus Program</h2>
                  <p>
                    Our Falcon Campus Program running for 12 years, hires
                    promising college graduates and provides training,
                    mentorship, and opportunities to launch successful digital
                    marketing careers.
                    <br />
                    Many of our current department heads began their journey
                    through this program, showcasing our commitment to growth
                    and development.
                  </p>
                  <a class="button" id="3dsolutionvideo" href="/contact-us/">
                    Get in touch
                  </a>
                </div>
                <div class="img-wrap">
                  <div className="slider-wrapper">
                    <Slider {...falcons_settings} className="inner-slider">
                      <div>
                        <img
                          alt="Copy write"
                          width="500"
                          height="500"
                          src={AboutUSRoundTwo}
                        />
                      </div>
                     
                      <div>
                        <img
                          alt="Copy write"
                          width="500"
                          height="500"
                          src={Falcons4}
                        />
                      </div> 
                      <div>
                        <img
                          alt="Copy write"
                          width="500"
                          height="500"
                          src={Falcons02}
                        />
                      </div>
                      <div>
                        <img
                          alt="Copy write"
                          width="500"
                          height="500"
                          src={Falcons3}
                        />
                      </div>
                      
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PagesecondIntro">
            <div class="container">
              <h2>Don't see a role that fits your profile?</h2>
              <p>
                We're always on the lookout for talented individuals to join our
                growing team. If you don't see a current opening that matches
                your skills and experience, please submit your resume using the
                form below. If we identify an opportunity for you to contribute
                to our success, we'll reach out to you promptly.
              </p>
              <a
                class="button"
                id="3dsolutionvideo"
                href="mailto:careers@position2.com"
              >
                Send resume
              </a>
            </div>
          </section>
          <section id="contactus-section" className="cybersecurity">
            <div className="container">
              <h2>Take the first step towards your growth marketing career</h2>
              <a class="button" id="3dsolutionvideo" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
